import React, { useContext } from 'react';
import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Box,
  CircularProgress,
  DialogTitle
} from '@mui/material';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { useErrorHandler } from 'src/hooks/useErrorHandler';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { ApolloError } from '@apollo/client';

import {
  useGetRequirementsSummaryLazyQuery,
  useGetCompletionCertificateLazyQuery
} from 'src/graphql/generated';
import { extractRequirements } from '../functions/extract-requirements';

export const DownloadCompletionCertificateButton = ({
  buttonText,
  year
}: {
  buttonText: string;
  year: string;
}) => {
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const handleError = useErrorHandler();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [getCompletionCertificateLazy] = useGetCompletionCertificateLazyQuery();
  const [getRequirementsSummaryLazyQuery] = useGetRequirementsSummaryLazyQuery();

  const handleStatementError = (error: ApolloError) => {
    handleError(error);
  };
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDownloadClick = () => {
    setIsLoading(true);
    getCpdStatement();
  };

  const getCpdStatement = () => {
    getRequirementsSummaryLazyQuery({
      variables: { memberid: activeMemberId, date: year + '-01-01' }, // 18-12-2024: decision to not use "cycleJustDate" and instead use "activeCycle + '-01-01'"
      fetchPolicy: 'cache-and-network',
      onError: handleError,
      onCompleted: (data) => {
        const { requirementMBA } = extractRequirements(data);
        const hasMetMbaRequirement = requirementMBA?.completed;

        if (!hasMetMbaRequirement) {
          setIsDialogOpen(true);
          setIsLoading(false);
        } else {
          getCompletionCertificateLazy({
            variables: {
              memberid: activeMemberId,
              year: year
            },
            fetchPolicy: 'no-cache',
            onError: handleStatementError,
            onCompleted: (data) => {
              setTimeout(() => {
                setIsLoading(false);
              }, 300);
              const attachment = data?.getAnnualCompletionCertificate;
              const url = attachment?.url;
              const attachment_name =
                attachment?.attachment_name ||
                `${year}_RACGP_CPD_Completion_Certificate_${activeMemberId}`;
              if (url) {
                triggerDownload(url, attachment_name);
              } else {
                handleError(
                  new Error(`Something went wrong, please refresh the page and try again`),
                  { sentry: true, errorBoundary: true }
                );
              }
            }
          });
        }
      }
    });
  };

  const triggerDownload = (url: string, fileName: string) => {
    const link = document.createElement('a');
    link.download = fileName;
    link.href = url;
    link.click();
  };

  return (
    <>
      <Button
        variant="outlined"
        fullWidth
        href=""
        target="_blank"
        sx={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'flex-end',
          justifyContent: 'flex-start',
          borderRadius: '8px',
          marginTop: 2,
          marginBottom: 4,
          fontWeight: 600,
          py: 1.5
        }}
        onClick={() => {
          handleDownloadClick();
        }}
      >
        <DescriptionOutlinedIcon sx={{ mr: 1, color: 'secondary.main' }} />
        {buttonText}
        <Box component="span" sx={{ display: 'inline-flex', pl: 1, marginLeft: 'auto' }}>
          {!isLoading && <FileDownloadOutlined />}
          {isLoading && <CircularProgress size="24px" color="inherit" />}
        </Box>
      </Button>

      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>CPD statement of completion</DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography>
            You have not met the MBA requirements for the year <strong>{year}</strong>.<br />
            <br />
          </Typography>
          <Typography>
            Your Certificate of completion will only be available once you have met all of the MBA
            requirements for the year.
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};
